import React from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor'
import baseImage from './assets/img/thum_4.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ImageEditor
          includeUI={{
            loadImage: {
              path: baseImage,
              name: 'SampleImage',
            },
            // theme: myTheme,
            initMenu: 'filter',
            uiSize: {
              width: '100vw',
              height: '100vh',
            },
            menuBarPosition: 'left',
          }}
          cssMaxHeight={500}
          cssMaxWidth={700}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={true}
        />
      </header>
    </div>
  )
}

export default App;
